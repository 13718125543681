import React, { Component } from 'react';

import ScrollupSection from '../components/scrollupSection/scrollUp';
import Header from '../components/headerSection/headerInv';
import WelcomeSection from '../components/welcomeSection/welcomeSol';
import AboutSection from '../components/aboutSection/aboutInv';
import WorkSection from '../components/workSection/workInv';
import FeatureSection from '../components/featureSection/featureInv';
import ScreenshotSection from '../components/screenshotSection/screenshotInv';
import PricingSection from '../components/pricingSection/pricingInv';
import ContactSection from '../components/contactSection/contactInv';
import FooterSection from '../components/footerSection/footerMain';
import Wssp from '../components/whatsapp/whatsapp';

class ThemeInv extends Component {
    render() {
        return (
            <div className="italy">
                <ScrollupSection />
                <div className="all-area">
                    <Header/>
                    <WelcomeSection />
                    <AboutSection />
                    <WorkSection />
                    <FeatureSection />
                    <ScreenshotSection />
                    <PricingSection />
                    {/*<DownloadSection />*/}
                    <ContactSection />
                    <FooterSection />
                    <Wssp />
                </div>
            </div>
        );
    }
}

export default ThemeInv;