import React, { Component } from 'react';
import db from "../../_json-server/tunexodb.json";


class workWeb extends Component {
    state = {
        initData: {},
        data: [],
        workData: []
    }
    componentDidMount(){
        this.setState({
            initData: db["themeTenWorkSectionWeb"],
            data: db["themeTenWorkSectionWeb"].imgData,
            workData: db["themeTenWorkSectionWeb"].workData
        })
    }
    render() {
        return (
            <section id="worksection" className="section work-area ptb_100">
                {/* Work Slider Wrapper */}
                <div className="work-wrapper d-none d-md-block">
                    <div className="work-slider-wrapper2" data-aos="zoom-in">
                        {/* Work Slider */}
                        <ul className="work-slider owl-carousel">
                            {this.state.data.map((item, idx) => {
                                return(
                                    <li key={`wd_${idx}`} className="slide-item">
                                        <img src={item.image} alt="" />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center d-md-none">
                        {/* Section Heading */}
                        <div className="col-12 col-md-10 col-lg-6">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-capitalize">{this.state.initData.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.initData.headingTextTwo}</p>
                            </div>
                        </div>
                    </div>
                    {/* Work Content */}
                    <div className="row justify-content-end justify-content-lg-between work-content" id="work-slider-pager">
                        {this.state.workData.map((item, idx) => {
                            return(
                                <div key={`wdo_${idx}`} className="col-12 col-sm-6">
                                    <a href="#" className={item.linkClass}>
                                    {/* Single Work */}
                                    <div className="single-work d-inline-block text-center p-4">
                                        <h3 className="mb-2">{item.title}</h3>
                                        <p>{item.text}</p>
                                    </div>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default workWeb;