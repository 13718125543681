import React, { Component } from 'react';
import axios from 'axios';
import db from "../../_json-server/tunexodb.json";

const enviarWssp = () => {
    console.log("Generando pedido");
    // let num_pedido = 738392;
    let num = 51912773356;
    let msg = `Soy *NOMBRE* y *MENSAJE*`;
    let link = `https://wa.me/${num}?text=${msg}`
    window.open(link, "_blank");
  };

class contactSectionInv extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: db["themeTenContactInvSection"]            
        })
        // console.log(this.state.data)
    }
    render() {
        return (
            <section id="contact" className="contact-area bg-gray ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-12">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-capitalize">{this.state.data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                                <p className="d-none d-sm-block mt-2">{this.state.data.headingTextTwo}</p>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default contactSectionInv;