import React, { Component } from 'react';

import ScrollupSection from '../components/scrollupSection/scrollUp';
import Header from '../components/headerSection/headerApp';
import WelcomeSection from '../components/welcomeSection/welcomeSol';
import AboutSection from '../components/aboutSection/aboutApp';
import WorkSection from '../components/workSection/workApp';
import ScreenshotSection from '../components/screenshotSection/screenshotApp';
import ScreenshotSection2 from '../components/screenshotSection/screenshotAppWeb';
import ContactSection from '../components/contactSection/contactApp';
import FooterSection from '../components/footerSection/footerMain';
import Wssp from '../components/whatsapp/whatsapp';

class ThemeAPP extends Component {
    render() {
        return (
            <div className="italy">
                <ScrollupSection />
                <div className="all-area">
                    <Header/>
                    <WelcomeSection />
                    <AboutSection />
                    <WorkSection />
                    <ScreenshotSection />
                    <ScreenshotSection2 />
                    <ContactSection />
                    <FooterSection />
                    <Wssp />
                </div>
            </div>
        );
    }
}

export default ThemeAPP;