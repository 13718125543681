import React, { Component } from 'react';
import ScrollupSection from '../scrollupSection/scrollUp';
import Header from '../headerSection/headerTwo';
import BreadcrumbSection from '../breadcrumbSection/breadcrumbTwo';
import FooterSection from '../footerSection/footer';
import axios from 'axios';
import db from "../../_json-server/tunexodb.json";

class termsConditionsCoins extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: db["termsConditionsSection"]
        })
    }
    render() {
        return (
            <div className="blog">
                <div className="all-area">
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <div className="row">
                                <div key={`btw_1`} className="col-12 col-lg-12">
                                    {/* Single Blog */}
                                    <div className="single-blog res-margin">
                                        <div className="">
                                            <img src={this.state.data.image} alt="" />
                                        </div>
                                        <h2 className="blog-title my-3">{this.state.data.title}</h2>
                                        <h3 className="blog-title my-3">Ultima actualización xx de xx de 2022</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elementum mauris sed faucibus sodales. Sed rhoncus nunc lectus, vitae pellentesque lorem accumsan at. Maecenas at sem eu purus cursus pellentesque sed in felis</p>
                                        <p>Fusce condimentum semper eros, a rhoncus nibh laoreet vitae. Nunc elementum, lorem vel dapibus varius, leo ligula condimentum sapien, et hendrerit augue tellus vel tellus. Donec dapibus quis felis eget imperdiet. Maecenas fermentum ligula nibh, nec malesuada dolor rhoncus a.</p>
                                        <br></br>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elementum mauris sed faucibus sodales. Sed rhoncus nunc lectus, vitae pellentesque lorem accumsan at. Maecenas at sem eu purus cursus pellentesque sed in felis</p>
                                        <p>Fusce condimentum semper eros, a rhoncus nibh laoreet vitae. Nunc elementum, lorem vel dapibus varius, leo ligula condimentum sapien, et hendrerit augue tellus vel tellus. Donec dapibus quis felis eget imperdiet. Maecenas fermentum ligula nibh, nec malesuada dolor rhoncus a.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">OBJETO</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elementum mauris sed faucibus sodales. Sed rhoncus nunc lectus, vitae pellentesque lorem accumsan at. Maecenas at sem eu purus cursus pellentesque sed in felis</p>
                                        <p>Fusce condimentum semper eros, a rhoncus nibh laoreet vitae. Nunc elementum, lorem vel dapibus varius, leo ligula condimentum sapien, et hendrerit augue tellus vel tellus. Donec dapibus quis felis eget imperdiet. Maecenas fermentum ligula nibh, nec malesuada dolor rhoncus a.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">TERMINOS DE USO</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elementum mauris sed faucibus sodales. Sed rhoncus nunc lectus, vitae pellentesque lorem accumsan at. Maecenas at sem eu purus cursus pellentesque sed in felis</p>
                                        <p>Fusce condimentum semper eros, a rhoncus nibh laoreet vitae. Nunc elementum, lorem vel dapibus varius, leo ligula condimentum sapien, et hendrerit augue tellus vel tellus. Donec dapibus quis felis eget imperdiet. Maecenas fermentum ligula nibh, nec malesuada dolor rhoncus a.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">REGISTRO DEL USUARIO</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elementum mauris sed faucibus sodales. Sed rhoncus nunc lectus, vitae pellentesque lorem accumsan at. Maecenas at sem eu purus cursus pellentesque sed in felis</p>
                                        <p>Fusce condimentum semper eros, a rhoncus nibh laoreet vitae. Nunc elementum, lorem vel dapibus varius, leo ligula condimentum sapien, et hendrerit augue tellus vel tellus. Donec dapibus quis felis eget imperdiet. Maecenas fermentum ligula nibh, nec malesuada dolor rhoncus a.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">DERECHOS DEL USUARIO</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elementum mauris sed faucibus sodales. Sed rhoncus nunc lectus, vitae pellentesque lorem accumsan at. Maecenas at sem eu purus cursus pellentesque sed in felis</p>
                                        <p>Fusce condimentum semper eros, a rhoncus nibh laoreet vitae. Nunc elementum, lorem vel dapibus varius, leo ligula condimentum sapien, et hendrerit augue tellus vel tellus. Donec dapibus quis felis eget imperdiet. Maecenas fermentum ligula nibh, nec malesuada dolor rhoncus a.</p>
                                        <br></br>
                                        <a href="/" className="btn btn-primary"><span>Ir a Inicio</span></a>
                                    </div>      
                                </div>                                        
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default termsConditionsCoins;