import React, { Component } from 'react';
import ScrollupSection from '../scrollupSection/scrollUp';
import Header from '../headerSection/headerTwo';
import BreadcrumbSection from '../breadcrumbSection/breadcrumbTwo';
import FooterSection from '../footerSection/footer';
import axios from 'axios';
import db from "../../_json-server/tunexodb.json";

class about extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: db["aboutUsSection"]
        })
    }
    render() {
        return (
            <div className="blog">
                <div className="all-area">
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <div className="row">
                                <div key={`btw_1`} className="col-12 col-lg-12">
                                    {/* Single Blog */}
                                    <div className="single-blog res-margin">
                                        <div className="">
                                            <img src={this.state.data.image} alt="" />
                                        </div>
                                        <h2 className="blog-title my-3">{this.state.data.title}</h2>
                                        <h3 className="blog-title my-3">Ultima actualización 09 de noviembre de 2020.</h3>
                                        <p>En mayo de 2020, cuando la crisis de la Pandemia por el nuevo coronavirus se agudizaba, decidimos poner en marcha el desarrollo de una oferta de valor para negocios y emprendedores que permitiera a cualquier persona tener acceso a una fuente de nexos con diversa oferta de productos, servicios y medios de pago. Esto se logra mediante una interfaz de búsqueda, contactabilidad, pago simple y eficaz con visibilidad en tiempo real del pedido y seguimiento.</p>
                                        <p>En ese mismo sentido, permite a los negocios el incremento de ventas por la presencia dinámica del negocio o emprendimiento en los motores de búsqueda de los clientes. Esto se logra mediante una interfaz flexible para el negocio que permite la carga de productos y servicios con medios de recaudo múltiples.</p>
                                        <p>Así nace TU NEXO. Una Aplicación móvil de DIGISOLUTIONS S.R.L, empresa formada por un equipo de emprendedores peruanos como tú, que pone a tu disposición esta APP gratuita.</p>
                                        <p>Nuestra primera versión o producto viable mínimo, conocida como MVP por sus siglas en inglés, permite la navegación libre y el contacto con los negocios a través de WhatsApp o contacto telefónico.</p>
                                        <p>Estamos trabajando en nuestra segunda versión que permitirá ya no solo la búsqueda y contacto, sino también la adquisición del catálogo de productos o contacto de servicios que ubiques, con métodos de pago digitales y accesibles.</p>
                                        <br></br>
                                        <a href="/" className="btn btn-primary"><span>Ir a Inicio</span></a>
                                    </div>      
                                </div>                                        
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default about;