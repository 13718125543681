import React, { Component } from 'react';
import db from "../../_json-server/tunexodb.json";

class headerInv extends Component {
    render() {
        return (
            <header className="section header-area">
                <div id="appo-header" className="main-header-area nav-white">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-md navbar-light">
                            {/* Logo */}
                            <a className="navbar-brand" href="#">
                                <img className="logo" src={this.props.imageData} alt="" />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
                                <span className="navbar-toggler-icon" />
                            </button>
                            {/* Appo Menu */}
                            <div className="collapse navbar-collapse" id="appo-menu">
                                {/* Header Items */}
                                <ul className="navbar-nav header-items ml-auto">
                                    <li className="nav-item">
                                        <a className="nav-link" href="/">IR A INICIO</a>
                                    </li>
                                    {/* <li className="nav-item active dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown">
                                        SOLUCIONES
                                        </a>
                                         REGISTRA TU NEXO Menu /// comentado
                                        <div className="dropdown-menu mega-menu blog-menu px-3 py-md-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className="single-menu">
                                                        <li><a className="dropdown-item" href="/theme-web">WEB DE PEDIDOS</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className="single-menu">
                                                        <li><a className="dropdown-item" href="/theme-app">APP TU NEXO</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>*/}
                                    <li className="nav-item">
                                        <a className="nav-link scroll" href="#about">BONDADES</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scroll" href="#worksection">DESCUBRE</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scroll" href="#features">CARACTERÍSTICAS</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scroll" href="#screenshots">DISEÑO</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scroll" href="#pricing">PLANES</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scroll" href="#contact">MÁS INFO</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        );
    }
}

export default headerInv;