import React, { Component } from 'react';
import db from "../../_json-server/tunexodb.json";


class wssp extends Component {
    state = {
        data: []
    }
    componentDidMount(){
        this.setState({
            data: db["themeTenWhatsappSection"]
        })
    }
    render() {
        return (
            <div>
                <a href={this.state.data.href} class="whatsApp" target="_blank">
                <img src={this.state.data.image}></img>        
            </a>

                
            </div>
        );
    }
}

export default wssp;