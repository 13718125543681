import React, { Component } from 'react';
import ScrollupSection from '../scrollupSection/scrollUp';
import Header from '../headerSection/headerTwo';
import BreadcrumbSection from '../breadcrumbSection/breadcrumbTwo';
import FooterSection from '../footerSection/footer';
import axios from 'axios';
import db from "../../_json-server/tunexodb.json";

class privacyPolicy extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: db["privacyPolicySection"]
        })
    }
    render() {
        return (
            <div className="blog">
                <div className="all-area">
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <div className="row">
                                <div key={`btw_1`} className="col-12 col-lg-12">
                                    {/* Single Blog */}
                                    <div className="single-blog res-margin">
                                        <div className="">
                                            <img src={this.state.data.image} alt="" />
                                        </div>
                                        <h2 className="blog-title my-3">{this.state.data.title}</h2>
                                        <h3 className="blog-title my-3">Ultima actualización 09 de noviembre de 2020</h3>
                                        <p>La presente política, en adelante la “Política”, tiene la finalidad de informar como la empresa DIGISOLUTIONS S.R.L. con R.U.C. N°20606168978 y domicilio en Av. Sergio Bernales N°528 Dpto.1404 Urbanización Barrio Médico, distrito de Surquillo, provincia y departamento de Lima, en adelante “TU NEXO”, trata la información y datos personales que recopilan a través de la interacción con los diferentes formularios digitales, sitios web, plataformas, redes sociales y aplicaciones móviles, así como cualquier otro medio electrónico o digital equivalente, en adelante “canales digitales”.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">1. SOBRE LA INFORMACIÓN QUE RECOLECTA TU NEXO</h3>
                                        <p>El Usuario tiene la libertad de navegar por, y en los canales digitales, que ofrece TU NEXO de manera libre y sin necesidad de registro o suscripción previa. Sin embargo, para determinadas funcionalidades, TU NEXO solicitará el registro y/o suscripción para acceder al contenido e información, para lo cual el usuario deberá completar un formulario proporcionando los datos personales básicos solicitados.</p>
                                        <p>De igual manera, en las secciones de los canales digitales de TU NEXO, en las que el Usuario tenga la opción de crear contenido, se solicitará el registro del Usuario mediante un formulario donde proporcionará los datos personales básicos solicitados.</p>
                                        <p>En lo que resulte aplicable, para los casos en que el Usuario decida registrarse utilizando el usuario y clave utilizada en servicios o plataformas de terceros, en adelante las “credenciales de terceros”, se entenderá que el Usuario acepta regirse por los términos y condiciones de dichos servicios o plataformas y autoriza además a TU NEXO a acceder a la información de la cuenta registrada en dicho servicio de acuerdo con los perfiles de privacidad de Usuario.</p>
                                        <p>Sobre el tratamiento de los Datos Personales y la Información de uso, en adelante la “Información del Usuario”, se efectuará de acuerdo con los lineamientos establecidos en esta Política.</p>
                                        <br></br>
                                        <p>Información del Usuario:</p>
                                        <ul className="single-menu">
                                            <li className="my-2 px-3">Son los datos personales que el Usuario proporcione libremente durante el registro o suscripción a canales digitales; tales como nombre, apellido, dirección de correo electrónico, número de teléfono, entre otros.</li>
                                        </ul>
                                        <br></br>
                                        <p>La Información sobre el comportamiento del Usuario en los Sitios Web y las Aplicaciones, en adelante la “Información de Uso”, incluye:</p>
                                        <ul className="single-menu">
                                            <li className="my-2 px-3">Información sobre aspectos operativos de los Sitios Web y las Aplicaciones, como tráfico, estadísticas de navegación y otros similares.</li>
                                            <li className="my-2 px-3">Información sobre las actividades realizadas por el Usuario en los Sitios Web y las Aplicaciones, cuando accede, visualiza, interactúa y comparte contenido, entre otros.</li>
                                            <li className="my-2 px-3">URLs más visitadas por el Usuario (esto incluye las URLs externas a los Sitios Web y las Aplicaciones).</li>
                                            <li className="my-2 px-3">La URL de la que proviene el Usuario (esto incluye las URLs externas a los Sitios Web y las Aplicaciones).</li>
                                            <li className="my-2 px-3">Direcciones IP.</li>
                                            <li className="my-2 px-3">Información sobre la ubicación del Usuario.</li>
                                            <li className="my-2 px-3">Navegador o celular que utiliza el Usuario.</li>
                                        </ul>
                                        <br></br>
                                        <h3 className="blog-title my-3">2. SOBRE LA VERACIDAD DE LOS DATOS PERSONALES</h3>
                                        <p>Mediante el registro o suscripción, el Usuario declara que toda la información que proporciona es verídica, completa y exacta. Cada Usuario es responsable por la veracidad, exactitud, vigencia y autenticidad de la información que suministra, y se compromete a mantenerla debidamente actualizada. En ese sentido, TU NEXO no se hace responsable de la veracidad de la información antes mencionada, por lo tanto, no asume responsabilidad alguna por posibles daños o perjuicios que pudieran originarse por el uso de dicha información.</p>
                                        <p>Sin perjuicio de lo anterior, el Usuario autoriza a TU NEXO a verificar la veracidad de los Datos Personales facilitados a través de fuentes de información de acceso público o de entidades especializadas en la provisión de dicha información.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">3. SOBRE LA INCORPORACIÓN DE LA INFORMACIÓN DEL USUARIO EN UN BANCO DE DATOS</h3>
                                        <p>De acuerdo con lo establecido en el marco normativo del Perú, Ley de Protección de Datos Personales N°29733 y su Reglamento, Decreto Supremo N°003-2013-JUS, en adelante la “normativa de protección de datos personales”, TU NEXO informa a los Usuarios de sus canales digitales, que la Información de Usuario será incorporada a los bancos de datos de titularidad de TU NEXO.</p>
                                        <p>Mediante la presente Política de Privacidad, el Usuario da su consentimiento expreso para la inclusión de su información en los mencionados bancos de datos.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">4. SOBRE LA FORMA EN QUE SE UTILIZA LA INFORMACIÓN DEL USUARIO</h3>
                                        <p>La Información del Usuario tiene el siguiente tratamiento:</p>
                                        <ul>
                                            <li className="my-2 px-3">Atender y procesar solicitudes de registro y/o suscripción de Usuario, brindar soporte y atender consultas, validar la veracidad de la información proporcionada.</li>
                                            <li className="my-2 px-3">Envío de información administrativa, como por ejemplo, información sobre los Sitios y los cambios en nuestros términos, condiciones y políticas. En vista que esta información puede ser importante para el uso de los Sitios, el Usuario no podrá optar por no recibir estas comunicaciones.</li>
                                            <li className="my-2 px-3">Para completar y cumplir con la compra, para el tratamiento de los pagos, entregas de pedido, comunicaciones con el Usuario en relación con la compra y proporcionar información sobre servicio al cliente.</li>
                                            <li className="my-2 px-3">Para proporcionar actualizaciones y novedades relativas a los productos y promociones, así como para el envío de invitaciones para participar en promociones especiales.</li>
                                            <li className="my-2 px-3">Para permitir el contacto con, o ser contactado por, otros Usuarios a través de los canales digitales, en la medida que el Sitio aplicable lo permita.</li>
                                            <li className="my-2 px-3">Gestionar y administrar las cuentas personales y/o corporativas que los Usuarios mantengan en los canales digitales, así como supervisar, monitorear y moderar el comportamiento y la actividad del Usuario en las secciones en las que el Usuario pueda crear o generar contenido e interacción, como las secciones de comentarios, conversaciones en foros, entre otros.</li>
                                            <li className="my-2 px-3">Gestionar los concursos y promociones que se realicen con los Usuarios. Informar sobre los ganadores de premios, promociones, concursos y/o sorteos realizados. Los Usuarios que participen en las promociones, concursos o sorteos mencionados, autorizan expresamente a que TU NEXO sea responsable de la promoción difunda, de manera gratuita y por los medios que estime convenientes, incluyendo los datos personales e imágenes de los ganadores.</li>
                                            <li className="my-2 px-3">Transferir la Información del Usuario a empresas que brinden servicios basados en infraestructura en la nube en el extranjero, a fin de poder alojar, almacenar, procesar y ejecutar la Información del Usuario.</li>
                                            <li className="my-2 px-3">Tratamiento con fines comerciales, publicitarios y/o promocionales con las siguientes finalidades:</li>
                                            <li className="my-2 px-5">Realizar estudios internos sobre los intereses, comportamientos y hábitos de conducta de los Usuarios a fin de poder enriquecer y complementar la Información del Usuario y de este modo ofrecer a los Usuarios un mejor servicio de acuerdo con sus necesidades específicas. Ello permitirá el envío de contenido personalizado sobre la base de sus intereses, ya sea a través canales digitales, como a través de otros medios no electrónicos.</li>
                                            <li className="my-2 px-5">Perfilar al Usuario enriqueciendo su información a través del procesamiento e interconexión de la misma con información obtenida de las empresas de TU NEXO o de empresas terceras no vinculadas y poder enviarle noticias, promociones de productos y/o servicios, encuestas, publicidad, invitaciones a eventos, email marketing, descuentos, cuponeras físicas y digitales, envíos de reportes de campaña y/o cualquier comunicación directa al Usuario final, novedades y otros, propios y/o de terceros, a través de comunicaciones remitidas a la dirección de correo electrónico y/o direcciones físicas que el Usuario facilitó al momento de realizar el registro y/o suscripción, y por los medios que TU NEXO estime conveniente. Para el caso de los correos electrónicos comerciales, el Usuario reconoce y acepta que estos serán considerados solicitados y que no califican como “spam” bajo la normativa vigente. En todo caso, el Usuario podrá notificar su voluntad de no recibir más mensajes de correo electrónico enviando una solicitud a tunexo.pe@gmail.com. También se incluirá un hipervínculo en todos los correos electrónicos para que el Usuario pueda revocar su consentimiento a través de dicho hipervínculo.</li>
                                            <li className="my-2 px-3">Transferir o compartir la Información del Usuario dentro del propio TU NEXO a fin de que las empresas que forman parte de la marca puedan contactar y ofrecer al Usuario información comercial, publicitaria y/o promocional sobre sus servicios y/o productos, ya sea a través de los canales digitales como a través de otros medios no electrónicos. Asimismo, la Información del Usuario podrá ser cedida o compartida con empresas terceras no vinculadas a TU NEXO a fin de que TU NEXO pueda contactar y ofrecer al Usuario cualquier información comercial, publicitaria y/o promocional sobre los productos y/o los servicios de empresas terceras de distintos sectores, incluyendo de manera enunciativa más no limitativa, a los siguientes rubros: inmobiliario, automotriz, servicios empresariales, banca y finanzas, grandes almacenes, entretenimiento,  consumo masivo, centros comerciales y comercios diversos, a través de los canales digitales, o a través de medios no electrónicos.</li>
                                            <li className="my-2 px-3">Ceder o compartir la Información del Usuario a proveedores de servicios de TU NEXO, a fin de que utilicen dicha información para mejorar o facilitar la operatividad de los canales digitales de TU NEXO, así como permitir la ejecución de los servicios contratados por el Usuario.</li>
                                        </ul>
                                        <br></br>
                                        <p>El Usuario manifiesta expresamente que ha sido debidamente informado de todas las finalidades antes mencionadas. Asimismo, a través de la aceptación de la presente Política de Privacidad, el Usuario autoriza su consentimiento previo, libre, expreso e inequívoco y gratuito, para el tratamiento de la Información del Usuario, de conformidad con las finalidades antes descritas.</p>
                                        <p>TU NEXO le recuerda al Usuario que el tratamiento de la información necesaria para la ejecución de la relación contractual que vincula al Usuario con TU NEXO no requiere de su consentimiento.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">5. SOBRE EL RESGUARDO DE LA INFORMACIÓN DEL USUARIO</h3>
                                        <p>TU NEXO adopta las medidas técnicas y organizativas necesarias para garantizar la protección de la Información del Usuario y evitar su alteración, pérdida, tratamiento y/o acceso no autorizado, habida cuenta del estado de la técnica, la naturaleza de los datos almacenados y los riesgos a que están expuestos, todo ello, conforme a lo establecido por la Ley de Protección de Datos Personales, su Reglamento y la Directiva de Seguridad.</p>
                                        <p>En tal sentido, TU NEXO usará los estándares de la industria en materia de protección de confidencialidad de la Información del Usuario. TU NEXO emplea diversas técnicas de seguridad para proteger tales datos de accesos no autorizados. Sin perjuicio de ello, el TU NEXO no se hace responsable por interceptaciones ilegales o violación de sus sistemas o bases de datos por parte de personas no autorizadas, así como la indebida utilización de la información obtenida por esos medios, o de cualquier intromisión ilegítima que escape al control de TU NEXO y que no le sea imputable.</p>
                                        <p>TU NEXO tampoco se hace responsable de posibles daños o perjuicios que se pudieran derivar de interferencias, omisiones, interrupciones, virus informáticos, averías telefónicas o desconexiones en el funcionamiento operativo de este sistema electrónico, motivadas por causas ajenas a TU NEXO; o de retrasos o bloqueos en el uso de la plataforma informática causados por deficiencias o sobrecargas en el Centro de Procesos de Datos, en el sistema de Internet o en otros sistemas electrónicos.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">6. SOBRE LA INFORMACIÓN DEL USUARIO SOLICITADA POR AUTORIDADES PÚBLICAS</h3>
                                        <p>TU NEXO se compromete a no divulgar o compartir la Información del Usuario sin que se haya prestado el debido consentimiento para ello, con excepción de los siguientes casos:</p>
                                        <ul>
                                            <li className="my-2 px-3">Solicitud de información de autoridades públicas en ejercicio de sus funciones y el ámbito de sus competencias.</li>
                                            <li className="my-2 px-3">Solicitud de información en virtud de órdenes judiciales.</li>
                                            <li className="my-2 px-3">Solicitud de información en virtud de disposiciones legales.</li>
                                        </ul>
                                        <br></br>
                                        <h3 className="blog-title my-3">7. COOKIES</h3>
                                        <p>Los canales digitales de TU NEXO utilizan cookies. Una “Cookie” es un pequeño archivo de texto que un sitio web almacena en el navegador del Usuario. Las cookies facilitan el uso y la navegación por una página web y son de importancia esencial para el buen funcionamiento de Internet, aportando innumerables ventajas en la prestación de servicios interactivos.</p>
                                        <p>A través de las cookies, los Sitios Web y las Aplicaciones podrán utilizar la información de su visita para realizar evaluaciones y cálculos estadísticos sobre datos anónimos, así como para garantizar la continuidad del servicio o para realizar mejoras.</p>
                                        <p>Asimismo, los canales digitales de tu NEXO también utilizarán la información obtenida a través de las cookies para analizar los hábitos de navegación del Usuario y las búsquedas realizadas por éste, a fin de mejorar sus iniciativas comerciales y promocionales, mostrando publicidad que pueda ser de su interés, y personalizando los contenidos.</p>
                                        <p>Las cookies pueden borrarse del disco duro si el Usuario así lo desea. La mayoría de los navegadores aceptan las cookies de forma automática, pero le permiten al Usuario cambiar la configuración de su navegador para que rechace la instalación de cookies, sin que ello perjudique su acceso y navegación por los canales digitales.</p>
                                        <p>En el supuesto de que en los canales digitales se dispusieran enlaces o hipervínculos hacia otros lugares de Internet propiedad de terceros que utilicen cookies, TU NEXO no se hace responsable ni controla el uso de cookies por parte de dichos terceros.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">8. SOBRE LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN DE DATOS PERSONALES</h3>
                                        <p>TU NEXO, hace conocimiento del Usuario y sus derechos de acceso, rectificación, oposición y cancelación de su información personal, así como los demás derechos concedidos por la normativa de protección de datos personales, los mismos que se podrán ejercer mediante petición escrita a la siguiente dirección de correo electrónico: tunexo.pe@gmail.com</p>
                                        <p>Sin perjuicio de lo anterior, TU NEXO podrá conservar determinada Información del Usuario que solicita la baja, a fin de que sirva de prueba ante una eventual reclamación contra TU NEXO por responsabilidades derivadas del tratamiento de dicha información. La duración de dicha conservación no podrá ser superior al plazo de prescripción legal de dichas responsabilidades.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">9. SOBRE LA CESIÓN DE POSICIÓN CONTRACTUAL</h3>
                                        <p>Los Usuarios autorizan expresamente la cesión de esta Política y de la Información del Usuario en favor de cualquier persona que (i) quede obligada por la presente Política; y/o (ii) que sea el nuevo responsable de los bancos de datos que contengan la Información del Usuario. Luego de producida la cesión, TU NEXO no tendrá ninguna responsabilidad con respecto de cualquier hecho que ocurra partir de la fecha de la cesión. El nuevo responsable del banco de datos asumirá todas y cada una de las obligaciones de TU NEXO establecidas en la presente Política respecto al tratamiento, resguardo y conservación de la Información del Usuario.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">10. MODIFICACIONES DE LAS POLÍTICAS DE PRIVACIDAD</h3>
                                        <p>TU NEXO se reserva expresamente el derecho a modificar, actualizar o completar en cualquier momento la presente Política.</p>
                                        <p>Cualquier modificación, actualización o ampliación producida en la presente Política será inmediatamente publicada en los canales digitales de TU NEXO y se le solicitará su aceptación al ingresar a las mismas.</p>
                                        <br></br>
                                        <a href="/" className="btn btn-primary"><span>Ir a Inicio</span></a>
                                    </div>      
                                </div>                                        
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default privacyPolicy;