import React, { Component } from 'react';

import ScrollupSection from '../components/scrollupSection/scrollUp';
import Header from '../components/headerSection/headerSol';
import WelcomeSection from '../components/welcomeSection/welcomeSol';
import PromoSection from '../components/promoSection/promoTen';
import AboutSection from '../components/aboutSection/aboutWeb';
import WorkSection from '../components/workSection/workWeb';
import FeatureSection from '../components/featureSection/featureWeb';
import ScreenshotSection from '../components/screenshotSection/screenshotWeb';
import PricingSection from '../components/pricingSection/pricingWeb';
import ReviewSection from '../components/reviewSection/reviewOne';
import TeamSection from '../components/teamSection/teamOne';
import SubscribeSection from '../components/subscribeSection/subscribeOne';
import DownloadSection from '../components/downloadSection/downloadWeb';
import BlogSection from '../components/blogSection/blogOne';
import ContactSection from '../components/contactSection/contactWeb';
import FooterSection from '../components/footerSection/footerWeb';
import Wssp from '../components/whatsapp/whatsapp';

class ThemeWeb extends Component {
    render() {
        return (
            <div className="italy">
                <ScrollupSection />
                <div className="all-area">
                    <Header/>
                    <WelcomeSection />
                    <AboutSection />
                    {/*<PromoSection />*/}               
                    <WorkSection />
                    {/*<FeatureSection />*/} 
                    <ScreenshotSection />
                    {/*<PricingSection />
                    <ReviewSection />
                    <TeamSection />
                    <SubscribeSection />
                    <DownloadSection />
                    <BlogSection />}
                    <ContactSection />*/}
                    <FooterSection />
                    <Wssp />
                </div>
            </div>
        );
    }
}

export default ThemeWeb;