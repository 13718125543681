import React, { Component } from 'react';

import ScrollupSection from '../components/scrollupSection/scrollUp';
import Header from '../components/headerSection/headerMain';
import WelcomeSection from '../components/welcomeSection/welcomeMain';
import FooterSection from '../components/footerSection/footerMain';
import Wssp from '../components/whatsapp/whatsapp';

class ThemeMain extends Component {
    render() {
        return (
            <div className="italy">
                <ScrollupSection />
                <div className="all-area">
                    <Header/>
                    <WelcomeSection />
                    <FooterSection />
                    <Wssp />
                </div>
            </div>
        );
    }
}

export default ThemeMain;