import React, { Component } from 'react';
import db from "../../_json-server/tunexodb.json";


class screenshotFed extends Component {
    state = {
        data: {},
        screendata: []
    }
    componentDidMount(){
        this.setState({
            data: db["themeTenScreenshotSectionInv"],
            screendata: db["themeTenScreenshotSectionInv"].screenshotData
        })
    }
    render() {
        return (
            <section id="screenshots" className="section screenshots-area ptb_100">
                <div className="container">
                    <div className="col-12 col-md-10 col-lg-12">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2 className="text-capitalize">{this.state.data.heading}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {/* App Screenshot Slider Area */}
                            <div className="app-screenshots">
                                {/* Single Screenshot Item */}
                                {this.state.screendata.map((item, idx) => {
                                    return(
                                        <div key={`sd_${idx}`} className="single-screenshot">
                                            <img src={item.image} alt="" />
                                            {/* Screenshots Overlay */}
                                            <div className="screenshots-overlay">
                                                <a href={item.image} data-fancybox="images"><i className={item.iconClass} /></a>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default screenshotFed;