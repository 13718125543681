import React, { Component } from 'react';
import ScrollupSection from '../scrollupSection/scrollUp';
import Header from '../headerSection/headerTwo';
import BreadcrumbSection from '../breadcrumbSection/breadcrumbTwo';
import FooterSection from '../footerSection/footer';
import axios from 'axios';
import db from "../../_json-server/tunexodb.json";

class termsConditions extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: db["termsConditionsSection"]
        })
    }
    render() {
        return (
            <div className="blog">
                <div className="all-area">
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <div className="row">
                                <div key={`btw_1`} className="col-12 col-lg-12">
                                    {/* Single Blog */}
                                    <div className="single-blog res-margin">
                                        <div className="">
                                            <img src={this.state.data.image} alt="" />
                                        </div>
                                        <h2 className="blog-title my-3">{this.state.data.title}</h2>
                                        <h3 className="blog-title my-3">Ultima actualización 09 de noviembre de 2020</h3>
                                        <p>Los presentes Términos y Condiciones Generales de uso e información legal (en adelante, Términos y Condiciones) se aplican al sitio web de DIGISOLUTIONS S.R.L. (en adelante TU NEXO), cuyo dominio es www.tunexo.pe y a la correspondiente aplicación móvil, así como a todos sus sitios relacionados o vinculados desde www.tunexo.pe, así como a sus filiales y a asociados, incluidos los sitios web de TU NEXO en todo el mundo (en adelante y de forma conjunta, el «Sitio»). El Sitio es propiedad de TU NEXO. Al utilizar el sitio, muestras tu conformidad con las presentes condiciones de uso. Si no estás de acuerdo, te rogamos te abstengas de utilizarlo.</p>
                                        <p>A través de los presentes Términos y Condiciones, TU NEXO pone a disposición de los usuarios (en adelante Usuario o Usuarios) el sitio web y la aplicación móvil TU NEXO (en adelante denominadas conjuntamente, la Plataforma).</p>
                                        <p>En cumplimiento de lo establecido en la normativa reguladora del país, se exponen los siguientes datos identificativos del titular del sitio y la Plataforma:</p>
                                        <br></br>
                                        <p>Razón social: DIGISOLUTIONS S.R.L.</p>
                                        <p>Domicilio Fiscal: Av. Sergio Bernales N°528 Dpto.1404 Urbanización Barrio Médico, distrito de Surquillo, provincia y departamento de Lima.</p>
                                        <p>Datos de inscripción en los Registros Públicos: Titulo 2020-00831894, Partida Registral 14491155, Asiento A0001.</p>
                                        <p>Registro Único de Contribuyente (RUC): 20606168978</p>
                                        <p>Web Site https://www.tunexo.pe</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">OBJETO</h3>
                                        <p>TU NEXO es una compañía tecnológica que tiene como actividad principal el desarrollo y gestión de plataformas tecnológicas como sitios web o aplicaciones móviles (en adelante, la APP) que permite a determinados negocios o emprendedores ofertar sus productos y/o servicios a través de la APP y de forma accesoria monitorear la entrega del producto o servicio.</p>
                                        <p>Los servicios y transacciones que se realicen a través del APP tendrán como marco legal los términos y condiciones que a continuación se detallan.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">TERMINOS DE USO</h3>
                                        <p>El acceso a la Plataforma, y la creación voluntaria de un perfil por parte del Usuario, implica el conocimiento y la aceptación expresa e inequívoca de los presentes Términos y Condiciones de Privacidad y la Política de Cookies por parte de todos los Usuarios.</p>
                                        <p>TU NEXO se reserva el derecho de modificar y/o actualizar los Términos y Condiciones en cualquier momento, sin necesidad de aviso alguno, por tal motivo se recomienda la revisión cada vez que se utiliza la aplicación web.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">REGISTRO DEL USUARIO</h3>
                                        <p>El Usuario tiene la libertad de navegar por, y en los sitios web y APP, que ofrece TU NEXO de manera libre y sin necesidad de registro previo. Sin embargo, para hacer uso de las funcionalidades de contactabilidad y adquisición que ofrece el APP, se requiere estar registrado. Esto se efectúa completando ciertos datos al usar la aplicación. La información ingresada por el usuario se considerará fidedigna y cierta, exonerando a TU NEXO de cualquier situación generada a causa de información falsa o inexacta proporcionada por el usuario al momento de su registro.</p>
                                        <br></br>
                                        <h3 className="blog-title my-3">DERECHOS DEL USUARIO</h3>
                                        <p>El usuario gozará de los derechos establecidos en la normativa sobre protección al consumidor, adicionalmente a los que se le brinden en la aplicación, a través de los Términos y Condiciones.</p>
                                        <p>El usuario goza de los derechos establecidos en la Ley N° 29733, Ley de Protección de Datos Personales y podrá ejercerlos de acuerdo con lo indicado en la Política de Privacidad.</p>
                                        <br></br>
                                        <a href="/" className="btn btn-primary"><span>Ir a Inicio</span></a>
                                    </div>      
                                </div>                                        
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default termsConditions;