import React, { Component } from 'react';

import ScrollupSection from '../components/scrollupSection/scrollUp';
import Header from '../components/headerSection/headerFed';
import WelcomeSection from '../components/welcomeSection/welcomeSol';
import AboutSection from '../components/aboutSection/aboutFed';
import WorkSection from '../components/workSection/workFed';
import FeatureSection from '../components/featureSection/featureFed';
import ScreenshotSection from '../components/screenshotSection/screenshotFed';
import PricingSection from '../components/pricingSection/pricingFed';
import ContactSection from '../components/contactSection/contactFed';
import FooterSection from '../components/footerSection/footerMain';
import Wssp from '../components/whatsapp/whatsapp';

class ThemeFed extends Component {
    render() {
        return (
            <div className="italy">
                <ScrollupSection />
                <div className="all-area">
                    <Header/>
                    <WelcomeSection />
                    <AboutSection />
                    <WorkSection />
                    <FeatureSection />
                    <ScreenshotSection />
                    <PricingSection />
                    <ContactSection />
                    <FooterSection />
                    <Wssp />
                </div>
            </div>
        );
    }
}

export default ThemeFed;