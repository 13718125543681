import React, { Component } from 'react';
import db from "../../_json-server/tunexodb.json";

class welcomeSol extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: db["themeTenWelcomeSection"] 
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area2 d-flex align-items-center h-100vh pt-0">
                
                <div className="shapes-container">
                    <div className="wave-animation">
                        {/* Wave Animation */}
                        <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use xlinkHref="#gentle-wave" x={48} y={0} fill="rgba(255,255,255,0.8)" />
                                <use xlinkHref="#gentle-wave" x={48} y={3} fill="rgba(255,255,255,0.5)" />
                                <use xlinkHref="#gentle-wave" x={48} y={5} fill="rgba(255,255,255,0.3)" />
                                <use xlinkHref="#gentle-wave" x={48} y={7} fill="rgba(255,255,255,1)" />
                            </g>
                        </svg>
                    </div>
                </div>
            </section>
        );
    }
}

export default welcomeSol;