import React, { Component } from 'react';
import ScrollupSection from '../scrollupSection/scrollUp';
import Header from '../headerSection/headerTwo';
import BreadcrumbSection from '../breadcrumbSection/breadcrumbTwo';
import FooterSection from '../footerSection/footer';
import axios from 'axios';
import db from "../../_json-server/tunexodb.json";

class frequentQuestions extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: db["frequentQuestionsSection"]
        })
    }
    render() {
        return (
            <div className="blog">
                <div className="all-area">
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <div className="row">
                                <div key={`btw_1`} className="col-12 col-lg-12">
                                    {/* Single Blog */}
                                    <div className="single-blog res-margin">
                                        <div className="">
                                            <img src={this.state.data.image} alt="" />
                                        </div>
                                        <h2 className="blog-title my-3">{this.state.data.title}</h2>
                                        <p>...</p>
                                        <br></br>
                                        <a href="/" className="btn btn-primary"><span>Ir a Inicio</span></a>
                                    </div>      
                                </div>                                        
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default frequentQuestions;